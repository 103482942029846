.ui {
    
    &-btn {
        display: inline-block;
        border-radius: 99px !important;
        background: $colorGrayLightest;
        font-weight: 500;
        transition: background-color 0.3s ease;
        white-space: nowrap;
        border: 0;
        margin: 0;
        padding: 0;
        
        &.-size-18-18-20 {
            font-size: 18px;
            line-height: 64px;
            height: 62px;
            padding: 0 35px 0 35px;
            
            @include from(lp) {
                font-size: 20px;
                line-height: 75px;
                height: 73px;
                padding: 0 35px 0 35px;
            }
        }

        &.-size-16-16-18 {
            font-size: 16px;
            line-height: 58px;
            height: 56px;
            padding: 0 30px 0 30px;
            
            @include from(lp) {
                font-size: 18px;
                line-height: 64px;
                height: 62px;
                padding: 0 35px 0 35px;
            }
        }

        &.-size-14-16-18 {
            font-size: 14px;
            line-height: 51px;
            height: 50px;
            padding: 0 25px 0 25px;
            
            @include from(mp) {
                font-size: 16px;
                line-height: 58px;
                height: 56px;
                padding: 0 30px 0 30px;
            }
            
            @include from(lp) {
                font-size: 18px;
                line-height: 64px;
                height: 62px;
                padding: 0 35px 0 35px;
            }
        }

        &.-size-14-14-18 {
            font-size: 14px;
            line-height: 51px;
            height: 50px;
            padding: 0 25px 0 25px;
            
            @include from(lp) {
                font-size: 18px;
                line-height: 64px;
                height: 62px;
                padding: 0 35px 0 35px;
            }
        }

        &.-size-14-14-16 {
            font-size: 14px;
            line-height: 51px;
            height: 50px;
            padding: 0 25px 0 25px;
            
            @include from(lp) {
                font-size: 16px;
                line-height: 58px;
                height: 56px;
                padding: 0 30px 0 30px;
            }
        }

        &.-green {
            background: $colorGreen;
            
            &:hover {
                background: $colorGreenLight;
            }
        }
        
        &.-red {
            background: $colorPeach;

            &:hover {
                background: $colorPeachLight;
            }
        }
        
        &.-white {
            background: $colorWhite;

            &:hover {
                background: $colorGreenLightest;
            }
        }
        
        &.-themed {
            background: $colorGreen;
            
            &:hover {
                background: $colorGreenLight;
            }
            
            .-theme-red & {
                background: $colorPeach;

                &:hover {
                    background: $colorPeachLight;
                }
            }
        }
        
        &.-themed-lightest {
            background: $colorGreenLightest;
            
            &:hover {
                background: $colorGreenLight;
            }
            
            &.-is-active {
                background: $colorGreen;
            }

            .-theme-red & {
                background: $colorPeachLightest;
            
                &:hover {
                    background: $colorPeachLight;
                }
                
                &.-is-active {
                    background: $colorPeach;
                }
            }
        }
        
    }
    
}
