.publication-modal {
    background: rgba(0, 0, 0, 0.8);
    
    &__col-adjust {
        @include until(s) {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
}
