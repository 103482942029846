.u {
    &-wrap {
        max-width: maxWidth() + (outerMargin()*2);
        padding-left: outerMargin();
        padding-right: outerMargin();
        margin-left: auto;
        margin-right: auto;

        @each $breakpoint in map-keys($breakpoints) {
            @include from($breakpoint) {
                padding-left: outerMargin($breakpoint);
                padding-right: outerMargin($breakpoint);
                max-width: maxWidth($breakpoint) + (outerMargin($breakpoint)*2);
            }
        }
    }

    &-clear {
        &:before, &:after {
            content: "";
            display: table;
        }

        &:after {
            clear: both;
        }
    }

    &-placed-wrap {
        position: relative;
        height: 0;
    }

    &-placed-image {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }

    $gutterBreakpoints: $breakpoints;
    $lastGutter: gutter();

    &-gutter {
        padding-left: gutter()/2;
        padding-right: gutter()/2;

        @each $breakpoint in map-keys($breakpoints) {
            @if gutter($breakpoint)!= $lastGutter {
                $lastGutter: gutter($breakpoint);

                @include from($breakpoint) {
                    padding-left: gutter($breakpoint)/2;
                    padding-right: gutter($breakpoint)/2;
                }
            }
        }

        @each $breakpoint in map-keys($gutterBreakpoints) {
            $lastGutter: 0;
            &\@#{$breakpoint} {
                @each $breakpoint in map-keys($gutterBreakpoints) {
                    @if gutter($breakpoint)!= $lastGutter {
                        $lastGutter: gutter($breakpoint);

                        @include from($breakpoint) {
                            padding-left: gutter($breakpoint)/2;
                            padding-right: gutter($breakpoint)/2;
                        }
                    }
                }
            }
            $gutterBreakpoints: map-remove($gutterBreakpoints, ($breakpoint));
        }
    }

    $gutterBreakpoints: $breakpoints;
    $lastGutter: gutter();

    &-gutter-l {
        padding-left: gutter()/2;

        @each $breakpoint in map-keys($breakpoints) {
            @if gutter($breakpoint)!= $lastGutter {
                $lastGutter: gutter($breakpoint);

                @include from($breakpoint) {
                    padding-left: gutter($breakpoint)/2;
                }
            }
        }

        @each $breakpoint in map-keys($gutterBreakpoints) {
            $lastGutter: 0;
            &\@#{$breakpoint} {
                @each $breakpoint in map-keys($gutterBreakpoints) {
                    @if gutter($breakpoint)!= $lastGutter {
                        $lastGutter: gutter($breakpoint);

                        @include from($breakpoint) {
                            padding-left: gutter($breakpoint)/2;
                        }
                    }
                }
            }
            $gutterBreakpoints: map-remove($gutterBreakpoints, ($breakpoint));
        }
    }

    $gutterBreakpoints: $breakpoints;
    $lastGutter: gutter();

    &-gutter-r {
        padding-right: gutter()/2;

        @each $breakpoint in map-keys($breakpoints) {
            @if gutter($breakpoint)!= $lastGutter {
                $lastGutter: gutter($breakpoint);

                @include from($breakpoint) {
                    padding-right: gutter($breakpoint)/2;
                }
            }
        }

        @each $breakpoint in map-keys($gutterBreakpoints) {
            $lastGutter: 0;
            &\@#{$breakpoint} {
                @each $breakpoint in map-keys($gutterBreakpoints) {
                    @if gutter($breakpoint)!= $lastGutter {
                        $lastGutter: gutter($breakpoint);

                        @include from($breakpoint) {
                            padding-right: gutter($breakpoint)/2;
                        }
                    }
                }
            }
            $gutterBreakpoints: map-remove($gutterBreakpoints, ($breakpoint));
        }
    }

    &-menu-pos {
        @include from(mp) {
            left: calc(50% - 90px) !important;
            width: calc(50% + 90px) !important;
        }
    }

    &-mega-head {
        font-size: 37px;
        line-height: 48px;

        @media (min-width: 400px) {
            font-size: 50px;
            line-height: 60px;
        }
        @media (min-width: 750px) {
            font-size: 93px;
            line-height: 100px;
        }
        @media (min-width: 1000px) {
            font-size: 62px;
            line-height: 73px;
            margin-top: -6px;
        }
        @media (min-width: 1100px) {
            font-size: 70px;
            line-height: 82px;
            margin-top: -8px;
        }
        @media (min-width: 1200px) {
            font-size: 75px;
            line-height: 88px;
            margin-top: -8px;
        }
        @media (min-width: 1400px) {
            font-size: 87px;
            line-height: 98px;
            margin-top: -7px;
        }
        @media (min-width: 1600px) {
            font-size: 100px;
            line-height: 113px;
            margin-top: -9px;
        }
        @media (min-width: 1800px) {
            font-size: 113px;
            line-height: 128px;
            margin-top: -13px;
        }
        @media (min-width: 2000px) {
            font-size: 133px;
            line-height: 150px;
            margin-top: -17px;
        }
        @media (min-width: 2400px) {
            font-size: 162px;
            line-height: 182px;
            margin-top: -23px;
        }
    }

    &-link-bg {
        box-shadow: inset 0 0 white, inset 0 -1px #000;
        transition: box-shadow 0.1s ease;

        &:hover {
            box-shadow: inset 0 0 white, inset 0 -2px #000;
        }

        &--alt-hover {
            &:hover {
                box-shadow: inset 0 0 white, inset 0 0px #000;
            }
        }

        &--nounderline {
            box-shadow: inset 0 0 white, inset 0 0 #000;

            &:hover {
                box-shadow: inset 0 0 white, inset 0 -1px #000;
            }
        }
    }


    &-link-body { // 18 / 18 / 22
        padding-bottom: 2px;

        @include from(mp) {
            padding-bottom: 2px;
        }
        @include from(lp) {
            padding-bottom: 2px;
        }
    }

    &-link-h3 { // 30 / 30 / 50
        padding-bottom: 2px;

        @include from(mp) {
            padding-bottom: 2px;
        }
        @include from(lp) {
            padding-bottom: 1px;
        }
    }

    &-link-h4 { // 20 / 22 / 30
        padding-bottom: 2px;
        @include from(mp) {
            padding-bottom: 2px;
        }
        @include from(lp) {
            padding-bottom: 1px;
        }
    }

    &-link-h6 { // 18 / 18 / 22
        padding-bottom: 2px;

        @include from(mp) {
            padding-bottom: 2px;
        }
        @include from(lp) {
            padding-bottom: 2px;
        }
    }

    &-link-h8 { // 16 / 16 / 18
        padding-bottom: 1px;

        @include from(lp) {
            padding-bottom: 2px;
        }
    }

    &-link-subnav { // 18 / 16 / 20
        padding-bottom: 2px;

        @include from(mp) {
            padding-bottom: 1px;
        }
        @include from(lp) {
            padding-bottom: 2px;
        }
    }


    &-breadcrumb {
        li {
            &:after {
                content: '/';
                display: inline-block;
                margin-left: 2px;
            }

            &:last-child:after {
                display: none;
            }

            &:first-child:after {
                display: inline-block;
            }
        }
    }

    &-publication-icon {
        width: 45px;
        height: 68px;
        
        @include from(s) {
            width: 55px;
            height: 83px;
        }

        &.-large {
            @include from(lp) {
                width: 75px;
                height: 113px;
            }
        }
    }


    &-news-thumb {
        @include until(sp) {
            .u-placed-wrap {
                width: 96px;
                padding-bottom: 100% !important;

                img {
                    object-fit: cover;
                }
            }
        }
        @include from(sp) {
            .u-placed-wrap {
                width: 100%;
            }
        }
    }

    &-news-tags {
        @include from(mp) {
            margin-top: 0.5em;
        }
    }

    &-c-theme {
        color: $colorGreen;

        .-theme-red & {
            color: $colorPeach;
        }
    }

    &-bg-theme {
        background-color: $colorGreen;

        .-theme-red & {
            background-color: $colorPeach;
        }
    }

    &-bg-theme-light {
        background-color: $colorGreenLight;

        .-theme-red & {
            background-color: $colorPeachLight;
        }
    }

    &-bg-theme-lightest {
        background-color: $colorGreenLightest;

        .-theme-red & {
            background-color: $colorPeachLightest;
        }
    }

    &-frontpage-top-illustration {
        width: calc(100% + (30px * 2));
        left: -30px;

        @include from(m) {
            width: calc(100% + (50px * 2));
            left: -50px;
        }

        @include from(mp) {
            width: calc(100% + 65px);
            left: -65px;
        }

        @include from(lp) {
            width: calc(100% + 85px);
            left: -85px;
        }

        &__inner {
            width: 100%;
            height: 0;
            padding-bottom: (645/773) * 100%;
        }
        
        &__width-guide {
            width: calc(100% - 30px);
            height: 0;
            
            @include from(m) {
                width: calc(100% - 50px);
            }
            
            @include from(mp) {
                width: calc(75% + 54px);
            }
            @include from(lp) {
                width: calc(75% + 59px);
            }
        }
    }
    
    &-publication-expand-text {
        &:target {
            .u-publication-expand-text__truncated {
                display: none;
            }

            .u-publication-expand-text__full {
                display: block;
            }
        }
    }

    &-anchor {
        display: block;
        position: relative;
        top: -100px;
        visibility: hidden;

        @include from(m) {
            top: -110px;
        }
    }
    
    &-grayscale-image-link {
        img {
            filter: grayscale(1);
            transition: filter 0.1s;
        }
        
        &:hover {
            img {
                filter: grayscale(0);
            }
        }
    }
    
}
