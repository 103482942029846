.search-form {

    input {
        border: 0;
        border-bottom: 1px solid $colorBlack;
        padding-right: 60px;
        
        @include from(mp) {
            padding-right: 80px;
        }
        
        @include from(lp) {
            padding-right: 100px;
        }
        
        &::placeholder {
            color: #ccc;
        }
    }

    button {
        background: none;
        border: 0;
        right: -9px;
        bottom: 0;
        width: 46px;
        height: 46px;
        cursor: pointer;

        @include from(mp) {
            width: 66px;
            height: 66px;
            right: -15px;
        }

        @include from(lp) {
            width: 92px;
            height: 92px;
            right: -22px;
        }


        svg {
            width: 28px;
            height: 28px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            @include from(mp) {
                width: 36px;
                height: 36px;
            }
            @include from(lp) {
                width: 48px;
                height: 48px;
            }
        }
    }
}
