.submenu {
    &__list {
        @include until(mp) {
            .-submenu-collapse & {
                padding-bottom: 5px;
                border-bottom: 1px solid $colorBlack;
            }
            .-submenu-collapse.-is-expanded & {
                padding-bottom: 15px;
            }
        }
    }

    &__item,
    &__submenu-item {
        .-submenu-collapse & {
            @include until(mp) {
                overflow: hidden;
            }
        }
    }

    &__item-link {
        padding-top: 20px;
        padding-bottom: 15px;
        border-bottom: 1px solid $colorBlack;

        @include from(lp) {
            padding-top: 30px;
            padding-bottom: 20px;
        }

        li:first-child & {
            padding-top: 0 !important;
        }

        .-submenu-collapse & {
            @include until(mp) {
                border-bottom: 0 !important;
                padding-top: 0 !important;
            }
        }


        .-has-children.-selected-branch & {
            padding-bottom: 0;
            border-bottom: 0 !important;
        }

    }

    &__submenu-item-link {
        padding-bottom: 15px;

        @include from(lp) {
            padding-bottom: 20px;
        }

        li:last-child & {
            //padding-bottom: 0 !important;
        }
    }

    &__item,
    &__submenu-item {
        .-submenu-collapse & {
            @include until(mp) {
                height: 0;
            }
        }

        &.-selected-leaf,
        &.-selected-branch {
            height: 100% !important;
        }
    }

    /*
     */

    &__submenu {
        padding-top: 20px;
        padding-bottom: 10px;
        margin-top: 15px;

        @include from(mp) {
            padding-bottom: 0;
        }
        
        @include from(lp) {
            padding-top: 50px;
            padding-bottom: 20px;
            margin-top: 20px;
        }

        @include until(mp) {
            .-submenu-collapse & {
                padding-top: 0;
                padding-bottom: 0;
            }

            .-submenu-collapse.-is-expanded & {
                padding-bottom: 15px;
            }
        }

        .-has-children.-selected-branch & {
            @include from(mp) {
                border-top: 1px solid $colorBlack;
            }
        }
    }


    &__submenu-item-link,
    &__item-link {
        span.-bullet {
            opacity: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 8px;
            height: 8px;
            border-radius: 50%;

            @include from(lp) {
                top: 15px;
                width: 12px;
                height: 12px;
            }
        }

        &.-selected {
            color: $colorBlack;

            span.-bullet {
                opacity: 1;
            }
        }
    }

    &__item-link {
        span.-bullet {
            @include until(mp) {
                display: none;
            }
        }
    }

    &__item-link-inner,
    &__submenu-item-link-inner {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__submenu-item-link-inner {
        @include until(mp) {
            padding-left: 50px;

            span.-bullet {
                right: auto;
                left: 21px;
            }
        }
    }

    &__toggle {
        border: 0;
        background: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        cursor: pointer;
        
        .-is-expanded & {
            width: 42px;
            height: 42px;
        }
        
        &-inner {
            width: 42px;
            height: 42px;
            top: -7px;
            right: -11px;

            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                background: $colorBlack;
                left: 50%;
                top: 50%;
            }
            
            &:before {
                width: 19px;
                height: 1px;
                margin: 0 0 0 -9px;
            }
            
            &:after {
                width: 1px;
                height: 19px;
                margin: -9px 0 0 0;
                transition: transform 0.4s $easeOutExpo;
                
                .-is-expanded & {
                    transform: scaleY(0);
                }
            }
        }

    }
}
