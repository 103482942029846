.menu-toggle-icon {
    width: 36px;
    height: 28px;
    
    @include from(m) {
        top: 1px;
    }
    
    span:nth-child(1),
    span:nth-child(2),
    span:nth-child(3) {
        height: 2px;
        width: 26px;
        left: 5px;
        transition: top 0.2s ease;
        
        @include from(mp) {
            height: 3px;
            width: 30px;
            left: 3px;
        }
    }
    
    span:nth-child(1) {
        top: 6px;
        
        [data-menu-toggle]:hover & {
            top: 5px;
        }
        
        @include from(mp) {
            top: 5px;

            [data-menu-toggle]:hover & {
                top: 4px;
            }
        }
    }
    span:nth-child(2) {
        top: 13px;
    }
    span:nth-child(3) {
        top: 20px;

        [data-menu-toggle]:hover & {
            top: 21px;
        }
        
        @include from(mp) {
            top: 21px;
                
            [data-menu-toggle]:hover & {
                top: 22px;
            }
        }
    }
}
