.fact-box {
    border: 1px solid $colorBlack;
    padding: 30px 40px;
    
    @include from(lp) {
        padding: 40px 50px;
    }
    
    &__content {
        max-height: 40px;
        overflow: hidden;
        position: relative;
        
        &:after {
            content: '';
            display: block;
            position: absolute;
            pointer-events: none;
            opacity: 1;
            width: 100%;
            height: 80px;
            bottom: 0;
            left: 0;
            background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.7) 50%, rgba(255,255,255,0) 100%);
            transition: opacity 0.5s ease;
        }
        
        &:target {
            max-height: none;
            
            &:after {
                display: none;
            }
        }
        
        .-is-opened & {
            &:after {
                opacity: 0;
            }
        }
    }
    
    &__toggle {
        width: 39px;
        height: 39px;
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid $colorBlack;
        bottom: -19px;
        left: calc(50% - 19px);
        transform: rotateZ(1);
        
        span {
            display: block;
            position: absolute;
            width: 13px;
            height: 13px;
            left: 12px;
            top: 9px;
            border-right: 2px solid $colorBlack;
            border-bottom: 2px solid $colorBlack;
            transform: rotate(45deg);
            transition-property: transform, top;
            transition-duration: 0.5s;
            transition-timing-function: $easeOutQuart;
            
            .-is-opened & {
                transform: rotate(-135deg);
                top: 14px;
            }
        }
    }
}
