.page-footer {
 
    &__logo-symbol {
        width: 77px;
        height: 55px;
        top: 1px;
        margin-right: 19px;
        
        @include from(lp) {
            width: 86px;
            height: 62px;
            margin-right: 28px;
            top: 2px;
        }
    }
    
    &__themed-input {
        border: 0 !important; 
        background: $colorGreenLight;
        
        .-theme-red & {
            background: $colorPeachLight;
        }
    }
    
    input::placeholder {
        color: $colorBlack;
    }
}
