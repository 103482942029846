.expandable-panels {
    
    li {
        border-top: 1px solid $colorGrayLightest;
        
        &:last-child {
            border-bottom: 1px solid $colorGrayLightest;
        }
    }
    
    &__toggle {
        span {
            display: block;
            position: absolute;
            width: 13px;
            height: 13px;
            right: 3px;
            top: 35px;
            border-right: 2px solid $colorBlack;
            border-bottom: 2px solid $colorBlack;
            transform: rotate(45deg);
            transition-property: transform, top;
            transition-duration: 0.5s;
            transition-timing-function: $easeOutQuart;
            
            @include from(lp) {
                top: 47px;
            }
            
            .-is-opened & {
                transform: rotate(-135deg);
                //top: 38px;
                
                @include from(lp) {
                    //top: 50px;
                }
            }
        }
    }
    
    &__content {
        height: 0;
        overflow: hidden;
        
        li:target & {
            height: auto;
        }
    }
    
    .-is-opened h3,
    li:target h3 {
        font-weight: 500;
    }
}
