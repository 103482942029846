.sticky-bar {
    transform: translateY(-100%);
    
    &__logo-symbol {
        width: 32px;
        height: 23px;
        
        @include from(mp) {
            width: 42px;
            height: 30px;
        }
    }
    
    .menu-toggle-icon {
        top: -3px;
        right: -5px;
        
        @include from(mp) {
            right: auto;
            left: -3px;
            top: 1px;
        }
    }
}
