.page-header {

    &__menu-toggle-text {
        padding-bottom: 5px;
        border-bottom: 2px solid currentColor;
        transition: padding-bottom 0.2s $easeOutQuint;

        .page-header__menu-toggle:hover & {
            padding-bottom: 11px;
        }
    }

    &__top-bar {
        .menu-toggle-icon {
            right: -5px;
            top: 0;
        }
    }

    &__search-form {
        top: -4px;
        
        @include from(lp) {
            top: -5px;
        }
        
        input {
            border: 0;
            border-bottom: 1px solid $colorBlack;
            padding-bottom: 4px;
            &::placeholder {
                color: $colorBlack;
            }
        }

        button {
            background: none;
            border: 0;
            right: -3px;
            top: 5px;
            width: 26px;
            height: 26px;
            cursor: pointer;

            @include from(lp) {
                width: 36px;
                height: 36px;
                right: -5px;
            }

            svg {
                width: 20px;
                height: 20px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                @include from(l) {
                    width: 26px;
                    height: 26px;
                }
            }
        }
    }

}
