.form {
    font-size: 16px;
    line-height: 1;

    @include from(lp) {
        font-size: 18px;
    }

    &__field {

    }

    .-field-error {
        input[type="text"],
        input[type="password"],
        input[type="number"],
        input[type="email"],
        textarea {
            //color: $colorOrange !important;
            border-color: $colorOrange !important;;
        }

        .form__check-label {
            color: $colorOrange !important;
        }
    }

    &__field-errors {
        color: $colorOrange;
        padding-left: 25px;
        padding-top: 8px;

        @include from(lp) {
            padding-left: 30px;
        }
    }


    button, button:active, button:focus {
        border: 0;
        outline: 0;
        cursor: pointer;
        margin: 0;
        //padding: 0;
        color: #000;
        //background: transparent;
        border-radius: 0;
    }

    label {
        display: block;
        padding-bottom: 10px;

        @include from(sp) {
            padding-left: 25px;
        }
        @include from(lp) {
            padding-left: 30px;
            padding-bottom: 20px;
        }
    }

    input[type="text"],
    input[type="password"],
    input[type="number"],
    input[type="email"],
    textarea {
        width: 100%;
        color: $colorGray;
        border: 1px solid $colorGrayLight;
        transition-property: color, border-color;
        transition-duration: 0.2s;
        transition-timing-function: ease;

        &:focus {
            border-color: $colorGray;
            color: $colorNearlyBlack;
        }

    }

    input[type="text"],
    input[type="password"],
    input[type="number"],
    input[type="email"] {
        padding: 0 20px 0 20px;
        height: 45px;

        @include from(lp) {
            padding: 0 30px 0 30px;
            height: 56px;
        }
    }

    textarea {
        padding: 15px 20px 15px 20px;
        min-height: 130px;

        @include from(lp) {
            padding: 15px 30px 15px 30px;
            min-height: 149px;
        }
    }


    /*
    * Custom checkboxes and radio buttons, ref https://css-tricks.com/the-checkbox-hack/
     */

    /* Base for label styling */
    input[type="checkbox"]:not(:checked),
    input[type="checkbox"]:checked,
    input[type="radio"]:not(:checked),
    input[type="radio"]:checked {
        position: absolute;
        left: -9999px;
    }

    input[type="checkbox"]:not(:checked) + label,
    input[type="checkbox"]:checked + label,
    input[type="radio"]:not(:checked) + label,
    input[type="radio"]:checked + label {
        position: relative;
        padding-left: 40px;
        cursor: pointer;

        @include from(sp) {
            padding-left: 70px;
        }
    }

    /* checkbox aspect */
    input[type="checkbox"]:not(:checked) + label:before,
    input[type="checkbox"]:checked + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 1px;
        width: 24px;
        height: 24px;
        border: 1px solid $colorGray;
        background: transparent;
        border-radius: 0;

        @include from(sp) {
            left: 30px;
        }
    }

    /* checked mark aspect */
    input[type="checkbox"]:not(:checked) + label:after,
    input[type="checkbox"]:checked + label:after {
        content: '';
        position: absolute;
        top: 9px;
        left: 7px;
        width: 10px;
        height: 9px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.25' height='8.8' viewBox='0 0 9.25 8.8'%3E%3Cpath d='M.647 2.436l3 5 5-7' fill='none' stroke='%231f1f1f' stroke-width='1.5'/%3E%3C/svg%3E");
        background-size: 10px 9px;

        @include from(sp) {
            left: 37px;
        }
    }

    /* checked mark aspect changes */
    input[type="checkbox"]:not(:checked) + label:after {
        opacity: 0;
    }

    input[type="checkbox"]:checked + label:after {
        opacity: 1;
    }

    /* checkbox aspect */
    input[type="radio"]:not(:checked) + label:before,
    input[type="radio"]:checked + label:before {
        content: '';
        position: absolute;
        left: 2px;
        top: 2px;
        width: 21px;
        height: 21px;
        border-radius: 50%;
        border: 1px solid $colorGray;
        background: transparent;

        @include from(sp) {
            left: 32px;
        }
    }

    /* checked mark aspect */
    input[type="radio"]:not(:checked) + label:after,
    input[type="radio"]:checked + label:after {
        content: '';
        position: absolute;
        top: 8px;
        left: 8px;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background: $colorGreen;

        @include from(sp) {
            left: 38px;
        }
    }

    /* checked mark aspect changes */
    input[type="radio"]:not(:checked) + label:after {
        opacity: 0;
    }

    input[type="radio"]:checked + label:after {
        opacity: 1;
    }

    /* disabled checkbox */
    input[type="checkbox"]:disabled:not(:checked) + label:before,
    input[type="checkbox"]:disabled:checked + label:before,
    input[type="radio"]:disabled:not(:checked) + label:before,
    input[type="radio"]:disabled:checked + label:before {
        opacity: 0.8 !important;
    }

    input[type="checkbox"]:disabled:checked + label:after,
    input[type="radio"]:disabled:checked + label:after {
        color: #888;
    }

    input[type="checkbox"]:disabled + label,
    input[type="radio"]:disabled + label {
        color: #aaa;
    }

    /* accessibility */
    input[type="checkbox"]:checked:focus + label:before,
    input[type="checkbox"]:not(:checked):focus + label:before {
        //border: 2px dotted blue;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    } 

}
