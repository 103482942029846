/*
*   Fonts
*
 */
$fontDefault: 'Gerstner', sans-serif;

/*
*   Colors
*
 */
$colorBlack: black;
$colorWhite: white;
$colorNearlyBlack: #1f1f1f;
$colorGreen: #AFD7BD;
$colorGreenLight: #D5E3D7;
$colorGreenLightest: #E7EBE8;
$colorPeach: #F3D1AB;
$colorPeachLight: #FAF0DD;
$colorPeachLightest: #F4F2EF;
$colorGray: #707070;
$colorGrayLight: #CBCBCB;
$colorGrayLightest: #E0E0E0;
$colorOrange: #FC5C47;


$colorText: $colorBlack;
$colorBackground: $colorWhite;

/*
*   Breakpoints
*
 */

// The different breakpoints are px values, but the unit has to be omitted
$small: 370;
$smallPlus: 600;
$medium: 750;
$mediumPlus: 1000;
$large: 1200;
$largePlus: 1400;
$xlarge: 1800;

$breakpoints: (
        s: $small,
        sp: $smallPlus,
        m: $medium,
        mp: $mediumPlus,
        l: $large,
        lp: $largePlus,
        xl: $xlarge,
);

/*
*   Gutters, margins, max width
*
 */

$gutterMap: (
        min: 30px,
        s: 30px,
        sp: 30px,
        m: 50px,
        mp: 50px,
        l: 50px,
        lp: 50px,
        xl: 50px
);

$outerMarginsMap: (
        min: 30px,
        s: 30px,
        sp: 30px,
        m: 50px,
        mp: 65px,
        l: 65px,
        lp: 85px,
        xl: 85px
);

$maxContentWidth: 2000px;

// If different max widths are needed, comment this in and comment the variable above out.
//
// $maxContentWidth: (
//     min: 1280px,
//     s: 1280px,
//     sp: 1280px,
//     m: 1280px,
//     mp: 1280px,
//     l: 1280px,
//     lp: 1440px,
//     xl: 1600px
// );

// Which unit should the breakpoints use? em, px or rem (em is default)
$breakpointsUnit: px; // PX, EM or REM Media Queries? -> https://zellwk.com/blog/media-query-units/

// Grid plugin settings
$grid-breakpointMap: $breakpoints;
$grid-breakpoints: (s, sp, m, mp, l, lp, xl);
$grid-columns: 8;
$grid-max-width: $maxContentWidth; //Should include support for multiple max-widths at different breakpoings. See NAF Digital
$grid-outer-margins: $outerMarginsMap;
$grid-gutters: $gutterMap;
$grid-flexbox: true;
// $grid-flexbox-test-class: 'flexbox';
$grid-pull-columns: 6;
$grid-push-columns: 6;
$grid-use-long-classnames: false;
$grid-use-em: false;
//$grid-max-width-map: $maxContentWidth; // Optional. Use if different max widths are needed at different breakpoints
