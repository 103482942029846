.wysiwyg,
.wysiwyg-simple,
.wysiwyg-list {
    strong, b {
        font-weight: 500;
    }
    
    em, i {
        font-style: italic;
    }
    
    ol {
        list-style-type: decimal;
    }
    
    ul {
        list-style-type: disc;
    }
    
    li {
        display: list-item;
        position: relative;
    }
}

.wysiwyg {
    p, table {
        margin-bottom: (29/18) * 1em;
        
        @include from(lp) {
            margin-bottom: (34/22) * 1em;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
    
    ul, ol {
        margin: 30px 0;

        @include from(lp) {
            margin: 40px 0;
        }
        
        &:last-child {
            margin-bottom: 0;
        }
    }
    
    h2 {
        margin-bottom: 20px;

        @include from(lp) {
            margin-bottom: 30px;
        }
    }
    
    p + h2,
    ul + h2,
    ol + h2 {
        margin-top: 50px;
        
        @include from(lp) {
            margin-top: 60px;
        }
    }
    
    ol {
        margin-left: 20px;
        
        @include from(lp) {
            margin-left: 23px;
        }
    }
    
    ul {
        margin-left: 20px;
        
        @include from(lp) {
            margin-left: 23px;
        }
    }
    
    li {
        padding-left: 6px;
        margin-bottom: 20px;
        
        &:last-child {
            margin-bottom: 0;
        }
    }
    
    table {
        width: 100%;
        border-collapse: collapse;
        text-align: left;
    }
    
    th {
        font-weight: 500;
    }
    
    th, td {
        padding: 30px 20px;
        vertical-align: top;
        
        &:first-child {
            padding-left: 0 !important;
        }
        &:last-child {
            padding-right: 0 !important;
        }
    }    
    
    tr {
        border-bottom: 1px solid $colorBlack;
    }
    
    
}

.wysiwyg-simple {
    p, ul, ol {
        margin-bottom: (26/16) * 1em;
        
        @include from(lp) {
            margin-bottom: (29/18) * 1em;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
    
    ol {
        margin-left: 20px;
        
        @include from(lp) {
            margin-left: 23px;
        }
    }
    
    ul {
        margin-left: 20px;
        
        @include from(lp) {
            margin-left: 23px;
        }
    }
    
    li {
        padding-left: 6px;
        margin-bottom: 15px;
        
        &:last-child {
            margin-bottom: 0;
        }
    }

}

.wysiwyg-list {
    p {
        margin-bottom: (26/16) * 1em;
        
        @include from(lp) {
            margin-bottom: (29/18) * 1em;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
    
    p + h3 {
        margin-top: 30px;
        
        @include from(lp) {
            margin-top: 40px;
        }
    }

    &.-two-columns {
        @include from(m) {
            column-count: 2;
            column-gap: gutter(m);
        }
        @include from(mp) {
            column-gap: gutter(mp);
        }
        @include from(l) {
            column-gap: gutter(l);
        }
        @include from(lp) {
            column-gap: gutter(lp);
        }
        @include from(xl) {
            column-gap: gutter(xl);
        }
    }
}
