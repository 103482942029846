.menu {
    &:target {
        height: 100vh;
    }

    &__submenu-toggle {
        background: 0;
        border: 0;
        padding: 0;
        margin: 0;
        width: 32px;
        height: 32px;
        cursor: pointer;
        top: 9px;
        
        @media (min-width: 370px) {
            top: 7px;
        }
        @media (min-width: 410px) {
            top: 5px;
        }

        @include from(lp) {
            width: 46px;
            height: 46px;
            top: 7px;
        }

        span {
            background: $colorBlack;
        }

        span:first-child {
            width: 14px;
            height: 2px;
            left: 8px;
            top: 14px;

            @media (min-width: 370px) {
                width: 18px;
                height: 2px;
                left: 6px;
                top: 14px;
            }
            @media (min-width: 410px) {
                width: 20px;
                height: 2px;
                left: 5px;
                top: 14px;
            }

            @include from(lp) {
                width: 26px;
                left: 9px;
                top: 21px;
            }
        }

        span:last-child {
            width: 2px;
            height: 14px;
            left: 14px;
            top: 8px;
            transition-property: height, top;
            transition-duration: 0.1s;
            transition-timing-function: $easeInOutSine;

            @media (min-width: 370px) {
                width: 2px;
                height: 18px;
                left: 14px;
                top: 6px;
            }
            @media (min-width: 410px) {
                width: 2px;
                height: 20px;
                left: 14px;
                top: 5px;
            }

            @include from(lp) {
                height: 26px;
                left: 21px;
                top: 9px;
            }
        }

        &.-is-open {
            span:last-child {
                height: 0;
                top: 14px;

                @include from(lp) {
                    top: 21px;
                }
            }
        }

    }

    &__items-list {
        font-size: 21px;
        line-height: 41px;

        @media (min-width: 370px) {
            font-size: 26px;
            line-height: 43px;
        }

        @media (min-width: 410px) {
            font-size: 30px;
            line-height: 55px;
        }

        @include from(lp) {
            font-size: 50px;
            line-height: 77px;
        }
    }

    &__top-bar {
        top: 0;
        left: 0;
        height: 120px;

        @include from(m) {
            height: 130px;
        }

        @include from(mp) {
            height: 120px;
        }

        @include from(lp) {
            height: 140px;
        }

        .page-header__menu-toggle {
            right: (gutter(min) / 2) - 5px;
            top: 35px;
            position: absolute;

            @include from(m) {
                top: 45px;
                right: (gutter(m) / 2) - 5px;
            }

            @include from(mp) {
                top: 40px;
                right: auto;
                left: (gutter(mp) / 2);
            }

            @include from(l) {
                left: (gutter(l) / 2);
            }

            @include from(lp) {
                top: 50px;
                right: auto;
                left: (gutter(lp) / 2);
            }

            @include from(xl) {
                left: (gutter(xl) / 2);
            }
        }
    }
    
    &__search-link {
        
        svg {
            width: 26px;
            height: 26px;
            top: 3px;
        }
    }
}
