/*

    @font-face declarations

    Paths to url() should be relative to the SASS entrypoint (base.scss), i.e. "../fonts/fontname.woff2"

 */

@font-face {
    font-family: 'Gerstner';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/GerstnerProgrammFSLWeb-Regular.woff2') format('woff2'),
    url('../fonts/GerstnerProgrammFSLWeb-Regular.woff') format('woff');
}
@font-face {
    font-family: 'Gerstner';
    font-weight: 500;
    font-style: normal;
    src: url('../fonts/GerstnerProgrammFSLWeb-Medium.woff2') format('woff2'),
    url('../fonts/GerstnerProgrammFSLWeb-Medium.woff') format('woff');
}
@font-face {
    font-family: 'Gerstner';
    font-weight: normal;
    font-style: italic;
    src: url('../fonts/GerstnerProgrammFSLWeb-Italic.woff2') format('woff2'),
    url('../fonts/GerstnerProgrammFSLWeb-Italic.woff') format('woff');
}
@font-face {
    font-family: 'Gerstner';
    font-weight: 500;
    font-style: italic;
    src: url('../fonts/GerstnerProgrammFSLWeb-MediumItalic.woff2') format('woff2'),
    url('../fonts/GerstnerProgrammFSLWeb-MediumItalic.woff') format('woff');
}
